<template>
  <div>
    <span class="text-decoration-underline cursor-pointer" @click="onCopy"
      >Copy</span
    >
    <div ref="copyRef" style="display: none; white-space: pre">
      <span v-html="text"></span>
    </div>
  </div>
</template>

<script>
import { copyText } from "@/libs/helpers";

export default {
  name: "TextCopy",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    text: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({}),
  computed: {},
  methods: {
    copyText,
    onCopy() {
      this.copyText(this.$refs.copyRef.textContent);
    },
  },
};
</script>

<style scoped></style>
